import { render, staticRenderFns } from "./ModuleProducts.vue?vue&type=template&id=99371ce6&"
import script from "./ModuleProducts.vue?vue&type=script&lang=js&"
export * from "./ModuleProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModuleHeader: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/ModuleHeader.vue').default,ProductCard: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/ProductCard.vue').default,ProductCardModuleOne: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/ProductCardModuleOne.vue').default,ModuleFooter: require('/furanet/sites/runnea.it/web/htdocs/nuxt/components/ModuleFooter.vue').default})
